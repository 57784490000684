import axios from 'axios';
import axiosInterceptor from './axiosInterceptor';
import {environment} from './environment';

const apacheServer = environment.URL + 'api/v1/';
const axiosApi = axios.create({
    baseURL: apacheServer,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInterceptor.setupInterceptors(axiosApi, true, false);
export default axiosApi;