import { Tokens } from '../constants';

export default {
    setupInterceptors: (axios, isToken = false, isFormData = false, dispatch) => {
        axios.interceptors.request.use(
            (config) => {
                if (isToken) {
                    if(process.env.REACT_APP_PORTAL === 'ADMIN') {
                        isToken = localStorage.getItem(Tokens.ADMIN);
                    } else {
                        isToken = localStorage.getItem(Tokens.VENDOR);
                    }
                    config.headers['Authorization'] = `Bearer ${isToken}`;
                }
                if (!isToken) {
                    if (!window.location.href.includes('login') && 
                        !window.location.href.includes('reset-password') && 
                        !window.location.href.includes('forgot-password')) {
                        window.location.href = '/login';
                    }
                }
                if (isFormData) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => successHandler(response),
            async (error) => {
                const originalRequest = error.config;

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true; // Prevent infinite loops

                    // Call your refresh token action
                    const refreshResponse = refreshToken();

                    if (refreshResponse && refreshResponse.error) {
                        window.location.href = '/login';
                        return Promise.reject(error);
                    }

                    // If the token refresh is successful, retry the original request
                    const newAccessToken = localStorage.getItem(Tokens.AUTH);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    return axios(originalRequest);
                }

                return Promise.reject(error);
            }
        );
    }
};

const successHandler = (response) => {
    return response;
};